import { SimpleCarousel, SimpleCarouselItem } from '@/components/carousel/SimpleCarousel'
import { CustomTrustpilot } from '@/components/common/CustomTrustpilot'
import { Headline2 } from '@/components/common/Headline2'
import { TestimonialBig } from '@/components/common/TestimonialBig'
import { Button } from '@/components/controls/Button'
import { CrossBtn } from '@/components/controls/CrossBtn'
import { Info } from '@/components/icons/Info'
import { Wing } from '@/components/icons/Wing'
import { useBetterMediaQuery } from '@/hooks/BetterMediaQuery'
import { useElementWidth } from '@/hooks/ElementWidth'
import { emitter } from '@/utils/emitter'

import { useQuery } from '@/utils/url'
import { useState } from 'react'
import Modal from 'react-modal'

export function TestimonialsVariantSection() {
  const sm = useBetterMediaQuery('(min-width: 768px)')
  const lg = useBetterMediaQuery('(min-width: 1024px)')
  const heroWidth = useElementWidth('hero-container', 100)
  const checkoutLink = useQuery('/checkout')
  const [show, setShow] = useState(false)

  const applyWidth = () => {
    if (!sm) {
      return heroWidth || 350
    }

    if (sm && !lg) {
      return 280
    }

    if (sm) {
      return 320
    }
  }

  const handleOpen = () => {
    emitter.emit('guarantee-info-opened')
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <section>
      <div className="container container--sec container--max-w-1111 text-center pb-70-to-130">
        <Headline2 tag="h2" className="text-[18px] md:text-[24px] mb-[10px] md:mb-[20px] !leading-[1.4]">See what our members say...</Headline2>
        <div className='flex justify-center h-[15px] scale-[0.9] mb-[30px] md:mb-[60px] mt-[5px]'>
          <CustomTrustpilot stars={true} large={true}></CustomTrustpilot>
        </div>
        {sm && <div className='flex flex-col md:flex-row justify-center'>
          <div className='flex justify-between text-left'>
            <div className='w-full max-w-[330px] md:mx-[20px]'>
              <TestimonialBig
                name="Caesar A. Silva"
                className="testimonial-hero-anim-less"
                text="I've been working 'alone' as an entrepreneur for 7 years now - I have core business and take on short to midterm projects with associates and temp employees to reach end goals..."
                initials="CA"
                height={255}
                url="https://www.trustpilot.com/reviews/63dd3abe9b64b1bdaf4c1f78"
              ></TestimonialBig>
            </div>
            <div className='w-full max-w-[330px] md:mx-[20px] md:hidden xl:block'>
              <TestimonialBig
                name="Jon S."
                className="testimonial-hero-anim-less"
                text="I could not be happier with my decision to join Commit Action. My accountability coach, Ryan, helps me push through the challenges and celebrate the wins. The materials..."
                initials="JS"
                initialsColor='#2e6ef3'
                height={255}
                url="https://www.trustpilot.com/reviews/62a730c96d54f92107147112"
              ></TestimonialBig>
            </div>
            <div className='w-full max-w-[330px] md:mx-[20px]'>
              <TestimonialBig
                name="Amberlee Kesser"
                className="testimonial-hero-anim-less"
                text="It’s hard to put into words how impactful working with Summer and commit action was on my life and business. She helped me break belief barriers, push myself past my..."
                initials="AK"
                initialsColor='#5ad082'
                height={255}
                url="https://www.trustpilot.com/reviews/636fb6fa252cba2c02d6b4c6"
              ></TestimonialBig>
            </div>
          </div>
        </div>}
        {!sm && <div className='text-left'><SimpleCarousel
          loop={true}
          width={applyWidth()}
          autoplay={true}
          animationSpeed={10000}
          gap={16}
          controlsVisible={false}
          endLess={true}
          bullets={true}
          overflow={'visible'}
          containerClass="checkout-testimonials-carousel2"
          nextHtml={<span className='checkout-testimonials-carousel_next_control home-page-carousel_next_control'></span>}
          prevHtml={<span className='checkout-testimonials-carousel_prev_control home-page-carousel_prev_control'></span>}
        >
          <SimpleCarouselItem>
            <TestimonialBig
              name="Caesar A. Silva"
              className="testimonial-hero-anim-less"
              text="I've been working 'alone' as an entrepreneur for 7 years now - I have core business and take on short to midterm projects with associates and temp employees to reach end goals..."
              initials="CA"
              height={255}
            ></TestimonialBig>
          </SimpleCarouselItem>
          <SimpleCarouselItem>
            <TestimonialBig
              name="Julie"
              className="testimonial-hero-anim-less"
              text="I kept getting ads for Commit Action on instagram as I scrolled, and I finally decided to take a look in a fit of frustration over yet another missed personal deadline. One of the best..."
              initials="JU"
              initialsColor='#2e6ef3'
              height={255}
            ></TestimonialBig>
          </SimpleCarouselItem>
          <SimpleCarouselItem>
            <TestimonialBig
              name="Amberlee Kesser"
              className="testimonial-hero-anim-less"
              text="It’s hard to put into words how impactful working with Summer and commit action was on my life and business. She helped me break belief barriers, push myself past my..."
              initials="AK"
              initialsColor='#5ad082'
              height={255}
            ></TestimonialBig>
          </SimpleCarouselItem>
          <SimpleCarouselItem>
            <TestimonialBig
              name="Liani Du Preez"
              className="testimonial-hero-anim-less"
              text="I was feeling as though my wheels were spinning and I wasn't getting anywhere. My coach Dallas is incredible and he has helped me get unstuck and get things done that..."
              initials="LD"
              height={255}
            ></TestimonialBig>
          </SimpleCarouselItem>
        </SimpleCarousel></div>}
        <div className='mt-[15px] md:mt-[30px] lg:mt-[40px] order-2 lg:order-1 flex justify-center'>
          <div className='w-full md:max-w-[380px]'>
            <Button href={checkoutLink} boldFont={true} type="primary" noWrap={true} textCenter={true} fullWidth={true} disableArrow={true} maxWidth={380}><Wing className='mr-[15px]'/>Get Paired With A Coach</Button>
            <p className='text-center mt-[10px] font--primary text-[17px] flex items-center justify-center cursor-pointer' onClick={handleOpen}><em className='mr-[4px]'>No Contract. 30-Day Guarantee</em><Info/></p>
          </div>
        </div>
      </div>
      <Modal isOpen={show} className='modal-content--white' ariaHideApp={false}>
        <CrossBtn className="modal__close-btn modal__close-btn-sec opacity-20" color="black" onClick={() => {
          handleClose()
        }}/>
        <div className='color--black mt-[20px]'>
          <Headline2 tag="h1" className="text-[22px] mb-[20px]">Try Our Service Risk Free</Headline2>
          <p className='mb-[20px]'>We’re confident that our service gets results, and we want you to give it a try.</p>
          <p className='mb-[20px]'>But if it doesn’t work for you, you shouldn’t pay. That’s why we offer a simple guarantee:</p>
          <p className='font-bold italic'>If you don’t feel like this is working for you and producing amazing results, then email us within the first 30 days and we’ll promptly refund your payment in full (no hard feelings)!</p>
        </div>
      </Modal>
    </section>
  )
}
