import { SimpleCarousel, SimpleCarouselItem } from '@/components/carousel/SimpleCarousel'
import { CustomTrustpilot } from '@/components/common/CustomTrustpilot'
import { Headline2 } from '@/components/common/Headline2'
import { TestimonialBig } from '@/components/common/TestimonialBig'
import { useBetterMediaQuery } from '@/hooks/BetterMediaQuery'
import { useElementWidth } from '@/hooks/ElementWidth'

export function TestimonialsVariantSection2() {
  const sm = useBetterMediaQuery('(min-width: 768px)')
  const lg = useBetterMediaQuery('(min-width: 1024px)')
  const heroWidth = useElementWidth('hero-container', 100)

  const applyWidth = () => {
    if (!sm) {
      return heroWidth || 350
    }

    if (sm && !lg) {
      return 280
    }

    if (sm) {
      return 320
    }
  }

  return (
    <section>
      <div className="container container--sec container--max-w-1111 text-center pb-70-to-130">
        <Headline2 tag="h2" className="text-[18px] md:text-[24px] mb-[10px] md:mb-[20px] !leading-[1.4]">See what our members say...</Headline2>
        <div className='flex justify-center h-[15px] scale-[0.9] mb-[30px] md:mb-[60px] mt-[5px]'>
          <CustomTrustpilot stars={true} large={true}></CustomTrustpilot>
        </div>
        {sm && <div className='flex flex-col md:flex-row justify-center'>
          <div className='flex justify-between text-left'>
            <div className='w-full max-w-[330px] md:mx-[20px]'>
              <TestimonialBig
                name="Danielle Dirks, Ph.D."
                className="testimonial-hero-anim-less"
                text="Commit Action, and my coach in particular, Brittany, have been nothing short of life changing this past year of working with them. So many of my peers ask how..."
                initials='DD'
                height={255}
                url="https://www.trustpilot.com/reviews/63fcf0d89b64b1bdaf6430c1"
              ></TestimonialBig>
            </div>
            <div className='w-full max-w-[330px] md:mx-[20px] md:hidden xl:block'>
              <TestimonialBig
                name="James F."
                className="testimonial-hero-anim-less"
                text="I’ve been working with my coach from Commit Action for about 3-4 months now and I can honestly say that I love everything about the experience..."
                initials='JF'
                initialsColor='#2e6ef3'
                height={255}
                url="https://www.trustpilot.com/reviews/6372ec09b84cc27618ec042d"
              ></TestimonialBig>
            </div>
            <div className='w-full max-w-[330px] md:mx-[20px]'>
              <TestimonialBig
                name="Louis Joseph"
                className="testimonial-hero-anim-less"
                text="Elizabeth Ma Has been an excellent coach and helped me improved as a professional and entrepreneur. She asks the right questions and provides very constructive feedback..."
                initials='LJ'
                initialsColor='#5ad082'
                height={255}
                url="https://www.trustpilot.com/reviews/63725dbb252cba2c02d855fb"
              ></TestimonialBig>
            </div>
          </div>
        </div>}
        {!sm && <div className='text-left'><SimpleCarousel
          loop={true}
          width={applyWidth()}
          autoplay={true}
          animationSpeed={10000}
          gap={16}
          controlsVisible={false}
          endLess={true}
          bullets={true}
          overflow={'visible'}
          containerClass="checkout-testimonials-carousel2"
          nextHtml={<span className='checkout-testimonials-carousel_next_control home-page-carousel_next_control'></span>}
          prevHtml={<span className='checkout-testimonials-carousel_prev_control home-page-carousel_prev_control'></span>}
        >
          <SimpleCarouselItem>
            <TestimonialBig
              name="Louis Joseph"
              className="testimonial-hero-anim-less"
              text="Elizabeth Ma Has been an excellent coach and helped me improved as a professional and entrepreneur. She asks the right questions and provides very constructive feedback..."
              initials='LJ'
              initialsColor='#5ad082'
              height={255}
            ></TestimonialBig>
          </SimpleCarouselItem>
          <SimpleCarouselItem>
            <TestimonialBig
              name="James F."
              className="testimonial-hero-anim-less"
              text="I’ve been working with my coach from Commit Action for about 3-4 months now and I can honestly say that I love everything about the experience..."
              initials='JF'
              initialsColor='#2e6ef3'
              height={255}
            ></TestimonialBig>
          </SimpleCarouselItem>
          <SimpleCarouselItem>
            <TestimonialBig
              name="Mo Chanmugham"
              className="testimonial-hero-anim-less"
              text="I'm a few months in and so thankful for my weekly check-ins with my coach. She keeps me accountable to my goals, helps me focus and prioritize..."
              initials='MC'
              height={255}
            ></TestimonialBig>
          </SimpleCarouselItem>
        </SimpleCarousel></div>}
      </div>
    </section>
  )
}
