import { Headline2 } from '@/components/common/Headline2'
import { Panel } from '@/components/common/Panel'
import { imageLoader } from '@/loaders/index'
import map from '@/public/images/map.png'
import Image from 'next/image'

export const OurCoachesSection: React.FC = () => {
  return (
    <section className="pt-70-to-130" id="our-coaches">
      <div className="container container--sec container--max-w-1111">
        <Headline2 tag="h2" className="text-center text-24-to-38 mb-[40px] lg:mb-[80px]">Our Commit Action Coaches</Headline2>
        <div className="flex flex-col md:flex-row justify-between items-center pb-70-to-130">
          <div className="md:w-[42%] shrink-0 pb-[70px] md:pb-0">
            <Headline2 tag="h3" className="text-20-to-30 mb-30-to-40 font-semibold" weight={800}>100% full time. 100% in-house. 100% the best.</Headline2>
            <p className="text-18-to-20 mb-[40px]">Our HQ is in NYC, but our coaches work remotely across the USA. Together, they’ve logged thousands of hours with members and worked with entrepreneurs from 1000’s different niches, across 22 different countries! <br/><br/> Every Commit Action Coach is...</p>
            <Panel className='font--accent text-[14px]'><b>Selected from a battery of psychological tests and profiles,</b> then interviewed exhaustively to ensure they’re the perfect fit. We hire less than 0.5% of applicants.</Panel>
            <Panel className='font--accent text-[14px]'><b>An expert in our evidence-based methodology,</b> designed to optimize your mental game and get you hitting goals faster than you ever though possible.</Panel>
            <Panel className='font--accent text-[14px]'><b>Trained for hundreds of hours working 1-to-1 with entrepreneurs,</b> so whether you’re talking about funding rounds, or your CAC to LTV ratio — they get it.</Panel>
            <Panel className='font--accent text-[14px]'><b>Experienced in a wide range of industries and niches;</b> from e-commerce to SaaS, real estate, agencies, and everything in between... we’ve helped businesses of all kinds hit their goals.</Panel>
          </div>
          <div className="md:w-[58%] ml-[-20px] mr-[-20px]">
            <Image loader={imageLoader} src={map} alt="Our HQ is in NYC, but our coaches work remotely across the USA." layout="intrinsic" />
          </div>
        </div>
      </div>
    </section>
  )
}
