interface Props {
  style?: React.CSSProperties,
  color?: string,
  className?: string
}

export function Shield({ style, color, className = '' }: Props) {
  return (
    <i className={`${className} icon`} style={style}>
      <svg fill={color || '#5ad082'} width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_518_5223)">
          <path d="M16.2584 1.87271L10.7765 0.0448357C10.597 -0.0149452 10.403 -0.0149452 10.2235 0.0448357L4.74163 1.87271C3.87004 2.16223 3.11186 2.71906 2.57481 3.46408C2.03776 4.2091 1.74916 5.10442 1.75 6.02284V10.5002C1.75 17.1178 9.8 20.7727 10.1448 20.925C10.2566 20.9746 10.3776 21.0003 10.5 21.0003C10.6224 21.0003 10.7434 20.9746 10.8553 20.925C11.2 20.7727 19.25 17.1178 19.25 10.5002V6.02284C19.2508 5.10442 18.9622 4.2091 18.4252 3.46408C17.8881 2.71906 17.13 2.16223 16.2584 1.87271ZM17.5 10.5002C17.5 15.2733 11.9709 18.4041 10.5 19.1531C9.02738 18.4067 3.5 15.2856 3.5 10.5002V6.02284C3.50005 5.47183 3.67349 4.93481 3.99575 4.48786C4.318 4.04091 4.77273 3.7067 5.2955 3.53259L10.5 1.79746L15.7045 3.53259C16.2273 3.7067 16.682 4.04091 17.0043 4.48786C17.3265 4.93481 17.5 5.47183 17.5 6.02284V10.5002Z"/>
          <path d="M13.3874 7.26244L9.72294 10.9374L7.75944 8.88994C7.68064 8.80379 7.5854 8.73427 7.47932 8.6855C7.37324 8.63672 7.25847 8.60967 7.14177 8.60593C7.02508 8.60219 6.90881 8.62185 6.79983 8.66374C6.69085 8.70563 6.59135 8.76891 6.5072 8.84984C6.42305 8.93078 6.35595 9.02774 6.30986 9.13501C6.26376 9.24228 6.2396 9.35769 6.23879 9.47445C6.23799 9.5912 6.26056 9.70693 6.30517 9.81483C6.34978 9.92273 6.41554 10.0206 6.49857 10.1027L8.51632 12.2027C8.66679 12.3652 8.84867 12.4956 9.05096 12.5858C9.25324 12.676 9.47172 12.7243 9.69319 12.7277H9.72207C9.93911 12.7284 10.1541 12.686 10.3547 12.6029C10.5552 12.5199 10.7372 12.3978 10.8902 12.2438L14.6282 8.50582C14.7098 8.42435 14.7746 8.3276 14.8189 8.22109C14.8631 8.11458 14.886 8.0004 14.8861 7.88506C14.8862 7.76972 14.8636 7.65549 14.8196 7.54889C14.7756 7.44229 14.711 7.3454 14.6295 7.26376C14.548 7.18212 14.4513 7.11732 14.3448 7.07307C14.2383 7.02882 14.1241 7.00598 14.0087 7.00586C13.8934 7.00574 13.7792 7.02833 13.6726 7.07236C13.566 7.11638 13.4691 7.18097 13.3874 7.26244Z"/>
        </g>
        <defs>
          <clipPath id="clip0_518_5223">
            <rect width="21" height="21" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </i>
  )
}
