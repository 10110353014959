import { emitter } from '@/utils/emitter'
import { Headline2 } from '@/components/common/Headline2'
import { Accordion } from '@/components/accordion/Accordion'
import { PhoneCaptureModal } from '@/components/modal/PhoneCaptureModal'
import { useState } from 'react'
import Link from 'next/link'
import { useQuery } from '@/utils/url'

export const FAQSectionVariant: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <section id="faq">
      <div className="container container--sec container--max-w-1111">
        <Headline2 tag="h2" className="text-36-to-48 mb-10-to-50">FAQs</Headline2>
        <Accordion items={[
          {
            title: 'How does Commit Action work?',
            active: false,
            text: `
            We pair you 1-on-1 with a highly trained, US-based accountability coach, who you speak to over the phone every week. <br/><br/>

            Their science-backed coaching will get you clarity on your most important work, and the ideal cognitive conditions to get it done. <br/><br/>

            It’s like having someone on your team whose sole job is optimizing your focus and productivity. <br/><br/>

            Long term, the Commit Action ritual gives you the structure and psychology to become the most effective version of yourself possible… and stay that way.
            `
          },
          {
            title: 'What does an accountability coach do?',
            active: false,
            text: `
            An accountability coach helps individuals stay on track with their goals and commitments. <br/><br/>

            They provide structure, guidance, and feedback, ensuring that the individual remains focused and makes consistent progress. <br/><br/>

            The coach typically sets up regular check-ins to review accomplishments, address challenges, and set the path forward. By holding their clients accountable, they foster a sense of responsibility, ensuring that tasks and goals are not just set, but also pursued and achieved.
            `
          },
          {
            title: 'How much should someone pay for an accountability coach?',
            active: false,
            text: `
            The cost of an accountability coach can vary widely based on factors such as the coach's experience, the complexity of the client's goals, and the frequency of coaching sessions. <br/><br/>

            In the context of accountability coaching, fees can range from $50 to $500 per session or more. Some coaches might offer packages that bundle several sessions at a discounted rate. When considering the fee, it's crucial to assess the value and outcomes you expect from the coaching relationship. <br/><br/>

            At Commit Action, it's $575 per month and you get 1-to-1 coaching sessions on the phone every week. Not only that, your accountability coaches are available via text or email around the clock.
            `
          },
          {
            title: 'How long are the weekly calls?',
            active: false,
            text: `
            The weekly calls are designed to be quick; a “minimum viable dose”. <br/><br/>

            This follows the behavioral science principle that you maximize long term results by making the behavior that drives them easy. <br/><br/>

            New customers would surely love to spend two hours really diving into our system, planning out their week to a level of outrageous precision. <br/><br/>

            But how many weeks could they feasibly keep that up? <br/><br/>

            By optimizing for consistency with a <em><b>tightly focused 30-minute ritual</b></em>, we’ve created a proven methodology for <em>incrementally</em> increasing your personal effectiveness… with results that compound over time. <br/><br/>

            Commit Action customers who keep the ritual going for a year become <em>unrecognizable</em> to their former selves: they transform into execution powerhouses.
            `
          },
          {
            title: 'Is there a contract?',
            active: false,
            text: `
            No. Commit Action is month-to-month (or year-to-year if you choose to pay annually and benefit from a 20% discount). There are no setup fees, cancellation fees, or long term contracts. You can quit at any time. We also offer a 30-day money back guarantee on both monthly and yearly plans, so you are safe to try the service out risk free. There is absolutely nothing to lose and everything to gain.
            `
          },
          {
            title: 'Do I need to have a business to sign up?',
            active: false,
            text: `
            No, we have a lot of people who are just starting businesses, as well as clients who are creatives and executives doing other stuff. You can use Commit Action to help you excel in any area or endeavor that you want. It is not just business accountability coaching. <br/><br/>

            As a premium service, we generally like to work with clients where there is a clear return-on-investment for the fee they pay us for our support. If you have ambition, autonomy, and a path to long term reward then we can help you make your big plans happen.
            `
          },
          {
            title: 'I have another question. Can I speak to someone before I start?',
            active: false,
            text: '',
            component: <span>
              Absolutely. <Link href={useQuery('/free-call', { source: 'faq-home' })} passHref><a className='link'>Click here to apply for a free call</a></Link> with one of our expert coaches. <br/><br/>
              You’ll be able to talk through your goals, your business, and your productivity. You can ask any questions you have about the service, and we will help match you with a coach who is a great fit for you.
            </span>
          },
        ]} onClose={(title) => {
          emitter.emit('faq-item-closed', title)
        }} onOpen={(title) => {
          emitter.emit('faq-item-opened', title)
        }} />
      </div>
      <PhoneCaptureModal show={showModal} handleClose={handleClose}/>
    </section>
  )
}
